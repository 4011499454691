import cloverUtils from 'utils/clover';
import orders from 'dataAccess/api/orders.ts';
import { copyText } from 'language';
import { getAgentName, getAgentEmail } from 'utils/agentUtils';
import clover from 'dataAccess/api/clover.ts';
import { addCartToHistory, updateProcessedCart } from 'utils/cartHistory';
import sendExchangeEmail from 'utils/exchangeEmailUtils';
import { createCart } from 'utils/cart';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import exchanges from 'dataAccess/api/exchanges.ts';

const cloverValidation = (setCheckoutDisabled) => {
  const cloverDevice = cloverUtils.getSessionCloverDevice();
  setCheckoutDisabled(!cloverDevice || cloverDevice === 'select');
};
const clearCart = async ({ oktaAuth, setCart }) => {
  const newCart = await createCart(
    getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
  );
  setCart(newCart);
  addCartToHistory(newCart);
};

const hasNoExchangeItems = (cart) => {
  if (!cart?.is_exchange_order) return false;
  const exchangeItems = JSON.parse(cart?.exchange_order_line_items ?? '[]');
  return exchangeItems.length === 0;
};

const isCheckoutDisabled = (cart, selectedAgent) => {
  const isCartEmpty = cart?.lineItems.length === 0;
  const isExchangeOrderEmpty = hasNoExchangeItems(cart);
  const isAgentNotSelected = !selectedAgent || selectedAgent?.email === 'not_set';
  return isCartEmpty || isAgentNotSelected || isExchangeOrderEmpty;
};

const exchangeValidation = ({ cart, setEmptyExchange, setCheckoutDisabled, selectedAgent }) => {
  if (cart?.is_exchange_order) {
    setEmptyExchange(hasNoExchangeItems(cart));
    const isDisabled = isCheckoutDisabled(cart, selectedAgent);
    setCheckoutDisabled(isDisabled);
    if (!isCheckoutDisabled(cart, selectedAgent)) {
      cloverValidation(setCheckoutDisabled);
    }
  }
};

const createOrder = async ({ paymentResult, cart, setOrderResponse, setOpen, setOrderOpen }) => {
  const order = await orders.createOrderFromCart(cart?.id, paymentResult);
  setOrderResponse(order.data);
  setOpen(false);
  setOrderOpen(true);
  return { order, orderCreated: true };
};

const createExchangeOrder = async ({ params, setOrderResponse, setOpen, setOrderOpen }) => {
  const orderResult = await exchanges.createExchangeOrder(params);
  setOrderResponse(orderResult.data);
  setOpen(false);
  setOrderOpen(true);
  return { order: orderResult, orderCreated: true };
};

const updateErrorMessage = (error, setErrorMessage) => {
  if (typeof error === 'string') {
    setErrorMessage(error);
  } else {
    // TODO: get the error message from the error object.
    setErrorMessage(`${copyText.Cart.CheckoutButtons.error} ${JSON.stringify(error)}`);
  }
};

const loadOrder = (orderNumber, setOrderOpen, history) => {
  setOrderOpen(false);
  history.push({ pathname: `/orders/order/${orderNumber}` });
};

const processOrder = async ({
  payment,
  cart,
  oktaAuth,
  setMessage,
  setOrderResponse,
  setOpen,
  setOrderOpen,
  setCreateOrderFailure,
  setErrorMessage,
}) => {
  try {
    await clover.displayThankYou({ setMessage });
    // create order, if success then clear cart
    if (cart?.is_exchange_order) {
      const params = {
        cartId: cart?.id,
        paymentResponse: payment,
        transactionType: 'charge',
        agentName: getAgentName(oktaAuth.authStateManager?._authState?.idToken?.claims?.name),
        agentEmail: getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
      };
      return await createExchangeOrder({
        params,
        exchanges,
        setOrderResponse,
        setOpen,
        setOrderOpen,
      });
    }
    return await createOrder({
      paymentResult: payment,
      cart,
      setOrderResponse,
      setOpen,
      setOrderOpen,
    });
  } catch (error) {
    setOpen(false);
    setCreateOrderFailure(true);
    updateErrorMessage(error, setErrorMessage);
    setMessage(error.message);
    return { orderCreated: false };
  } finally {
    setTimeout(() => {
      clover.displayWelcome({ setMessage });
    }, 3000);
  }
};

const receiptFunction = async ({
  orderResult,
  payment,
  cart,
  setMessage,
  oktaAuth,
  setCart,
  setOpen,
  setCreateOrderFailure,
  setErrorMessage,
}) => {
  try {
    if (orderResult.orderCreated) {
      await clover.printReceipt(payment.data, orderResult.order.data, setMessage);
      if (cart?.is_exchange_order) {
        await sendExchangeEmail(orderResult.order.data);
      }
      updateProcessedCart(cart.id);
      await clearCart({ createCart, oktaAuth, setCart, addCartToHistory });
    } else {
      throw new Error('Order creation failed.');
    }
  } catch (error) {
    setOpen(false);
    setCreateOrderFailure(true);
    updateErrorMessage(error, setErrorMessage);
    setMessage(error.message);
  }
};

const paymentResponse200 = async ({
  payment,
  cart,
  setMessage,
  oktaAuth,
  setCart,
  setOpen,
  setCreateOrderFailure,
  setErrorMessage,
  setOrderResponse,
  setOrderOpen,
}) => {
  let orderResult = {};
  try {
    orderResult = await processOrder({
      payment,
      clover,
      cart,
      oktaAuth,
      setMessage,
      exchanges,
      setOrderResponse,
      setOpen,
      setOrderOpen,
      setCreateOrderFailure,
      setErrorMessage,
    });
    await receiptFunction({
      orderResult,
      payment,
      cart,
      setMessage,
      sendExchangeEmail,
      updateProcessedCart,
      createCart,
      oktaAuth,
      setCart,
      addCartToHistory,
      setOpen,
      setCreateOrderFailure,
      setErrorMessage,
    });
  } catch (error) {
    setMessage(error.message);
  }
};

const handlePaymentResponse = async ({
  payment,
  cart,
  setMessage,
  oktaAuth,
  setCart,
  setOpen,
  setCreateOrderFailure,
  setErrorMessage,
  setOrderResponse,
  setOrderOpen,
}) => {
  switch (payment.status) {
    case 200:
      await paymentResponse200({
        payment,
        exchanges,
        cart,
        setMessage,
        createCart,
        oktaAuth,
        setCart,
        setOpen,
        setCreateOrderFailure,
        setErrorMessage,
        setOrderResponse,
        setOrderOpen,
      });

      break;
    case 209: {
      setTimeout(() => {
        clover.displayWelcome();
      }, 3000);
      break;
    }

    case 400: {
      setTimeout(() => {
        clover.displayWelcome();
      }, 3000);
      break;
    }
    case 401: {
      break;
    }
    case 415: {
      break;
    }
    case 500: {
      break;
    }
    case 501: {
      break;
    }
    case 503: {
      break;
    }
    case 504: {
      break;
    }
    default: {
      break;
    }
  }
};

const handleCashPayment = async ({
  cart,
  setLoading,
  setPaymentResponse,
  setOpen,
  oktaAuth,
  setOrderResponse,
  setOrderOpen,
  checkRefund,
  setCart,
  setCreateOrderFailure,
  setErrorMessage,
}) => {
  try {
    setLoading(false);
    setPaymentResponse({ status: 200 });
    setOpen(true);
    let order;
    if (cart.is_exchange_order) {
      const params = {
        cartId: cart?.id,
        paymentResponse: null,
        transactionType: 'refund',
        agentName: getAgentName(oktaAuth.authStateManager?._authState?.idToken?.claims?.name),
        agentEmail: getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
      };
      order = await createExchangeOrder({
        params,
        exchanges,
        setOrderResponse,
        setOpen,
        setOrderOpen,
      });
      // send exchange email
      await sendExchangeEmail(order.data, checkRefund);
    } else {
      order = await orders.createCashOrder(cart?.id);
    }
    setOpen(false);
    setOrderResponse(order.data);

    setOrderOpen(true);
    await clearCart({ createCart, oktaAuth, getAgentEmail, setCart });
  } catch (error) {
    setOpen(false);
    setCreateOrderFailure(true);
    updateErrorMessage(error, setErrorMessage);
  }
};

const handleRetry = ({
  setOpen,
  cart,
  paymentResponse,
  setMessage,
  setLoading,
  setPaymentResponse,
  checkRefund,
  oktaAuth,
  setCart,
  setCreateOrderFailure,
  setErrorMessage,
  setOrderResponse,
  setOrderOpen,
}) => {
  setOpen(true);
  if (financialCalculators.getTotalPrice(cart) > 0) {
    handlePaymentResponse({
      payment: paymentResponse,
      exchanges,
      cart,
      setMessage,
      sendExchangeEmail,
      createCart,
      oktaAuth,
      setCart,
      setOpen,
      setCreateOrderFailure,
      setErrorMessage,
      setOrderResponse,
      setOrderOpen,
    });
  } else {
    handleCashPayment({
      cart,
      setLoading,
      setPaymentResponse,
      setOpen,
      oktaAuth,
      exchanges,
      setOrderResponse,
      setOrderOpen,
      checkRefund,
      setCart,
      setCreateOrderFailure,
      setErrorMessage,
    });
  }
};

const sendPaymentRequest = async ({
  manualEntry,
  cart,
  setOpen,
  oktaAuth,
  setLoading,
  setPaymentResponse,
  setCreateOrderFailure,
  setMessage,
  setErrorMessage,
  setOrderResponse,
  setOrderOpen,
  checkRefund,
  setCart,
}) => {
  try {
    const totalPrice = financialCalculators.getTotalPrice(cart);
    if (totalPrice === 0) {
      handleCashPayment({
        cart,
        setLoading,
        setPaymentResponse,
        setOpen,
        oktaAuth,
        exchanges,
        setOrderResponse,
        setOrderOpen,
        checkRefund,
        setCart,
        setCreateOrderFailure,
        setErrorMessage,
      });
      return;
    }
    if (cart.id && totalPrice !== 0) {
      setOpen(true);
      setLoading(true);
      const paymentData = {
        cartId: cart.id,
        paymentTotal: totalPrice,
        manualEntry,
        splitPayments: false,
      };
      const result = await clover.paymentRequest(paymentData);
      setLoading(false);
      setPaymentResponse(result);
      handlePaymentResponse({
        payment: result,
        cart,
        setMessage,
        oktaAuth,
        setCart,
        setOpen,
        setCreateOrderFailure,
        setErrorMessage,
        setOrderResponse,
        setOrderOpen,
      });
      setLoading(false);
    } else {
      throw new Error(copyText.Cart.CartTools.paymentFailed);
    }
  } catch (error) {
    setOpen(false);
    setCreateOrderFailure(true);
    updateErrorMessage(error, setErrorMessage);
  }
};

export {
  cloverValidation,
  clearCart,
  hasNoExchangeItems,
  isCheckoutDisabled,
  exchangeValidation,
  createOrder,
  createExchangeOrder,
  updateErrorMessage,
  loadOrder,
  processOrder,
  receiptFunction,
  paymentResponse200,
  handlePaymentResponse,
  handleCashPayment,
  handleRetry,
  sendPaymentRequest,
};
