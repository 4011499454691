import React, { useEffect, useState, useRef } from 'react';
import { Button, Box, Typography } from '@mui/material';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import { copyText } from 'language';
import cloverIframeUtils from './cloverIframeUtils';

const CloverIframe = () => {
  const cloverContainerId = 'clover-container';
  const [cloverReady, setCloverReady] = useState(false);
  const [message, setMessage] = useState('');
  const cloverInstanceRef = useRef(null);

  // Effect to load the Clover script only once
  useEffect(() => {
    cloverIframeUtils.loadCloverScript({
      setCloverReady,
      setMessage,
    });
    return () => {
      if (cloverInstanceRef.current) {
        cloverIframeUtils.unloadCloverIframe(cloverInstanceRef.current);
        cloverInstanceRef.current = null;
      }
    };
  }, []);

  // Load the iframe when Clover is ready
  useEffect(() => {
    if (cloverReady && !cloverInstanceRef.current) {
      const instance = cloverIframeUtils.loadIframe({
        cloverContainerId,
        setMessage,
      });
      if (instance) {
        cloverInstanceRef.current = instance;
      }
    }
  }, [cloverReady]);

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">{copyText.Cart.CheckoutStepper.enterCardDetails}</Typography>
      <div
        id={cloverContainerId}
        style={{
          padding: '10px',
          marginTop: '10px',
        }}
      />
      <Button
        disabled={!cloverReady}
        onClick={() =>
          cloverIframeUtils.handleProcessPayment({
            cloverInstanceRef,
            setMessage,
          })
        }
        variant="contained"
        sx={{ mt: 4 }}
      >
        {copyText.Cart.CheckoutStepper.processPayment}
      </Button>
      <SnackbarMessage message={message} setMessage={setMessage} />
    </Box>
  );
};

export default CloverIframe;
